export enum Nav {
  Home = '/',
  Register = '/register',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  SetupPassword = '/setup-password',
  Login = '/login',
  Auth = '/auth',
  Logout = '/logout',
  Templates = '/templates',
  Template = '/templates/:id/*',
  TemplateContract = '/templates/:id/contract',
  Blocks = '/blocks',
  Branding = '/branding',
  BrandingDetails = '/branding/:id',
  Tags = '/tags',
  Users = '/users',
  Submissions = '/submissions',
  Submission = '/submissions/:id/*',
  SubmissionForm = '/submissions/:id/form',
  SubmissionContract = '/submissions/:id/contract',
  Endorsements = '/submissions/:id/endorsements',
  Endorsement = '/submissions/:id/endorsements/:endorsement_id/*',
  EndorsementForm = '/submissions/:id/endorsements/:endorsement_id/form',
  EndorsementContract = '/submissions/:id/endorsements/:endorsement_id/contract',
  EndorsementView = '/submissions/:id/endorsements/:endorsement_id/endorsement-view',
  MrcExtraction = '/submissions/mrc-extraction/:submissionId/*',
  MrcExtractionDocumentUpload = '/submissions/mrc-extraction/:submissionId/upload',
}

type RouteConfig = {
  title: string;
  access: 'private' | 'public';
  layout?: 'user';
};

export const config: Record<Nav, RouteConfig> = {
  [Nav.Home]: { title: 'Home', access: 'private' },
  [Nav.Register]: { title: 'Register', access: 'public' },
  [Nav.ForgotPassword]: { title: 'Forgot Password', access: 'public' },
  [Nav.ResetPassword]: { title: 'Reset Password', access: 'public' },
  [Nav.SetupPassword]: { title: 'Setup Password', access: 'public' },
  [Nav.Login]: { title: 'Login', access: 'public' },
  [Nav.Auth]: { title: 'Auth', access: 'public' },
  [Nav.Logout]: { title: 'Logout', access: 'public' },
  [Nav.Templates]: { title: 'Templates', access: 'private', layout: 'user' },
  [Nav.Template]: { title: 'Template', access: 'private' },
  [Nav.TemplateContract]: { title: 'Template Contract', access: 'private' },
  [Nav.Blocks]: { title: 'Block Library', access: 'private', layout: 'user' },
  [Nav.Branding]: { title: 'Branding', access: 'private', layout: 'user' },
  [Nav.BrandingDetails]: { title: 'BrandingDetails', access: 'private', layout: 'user' },
  [Nav.Tags]: { title: 'Tags', access: 'private', layout: 'user' },
  [Nav.Users]: { title: 'Users', access: 'private', layout: 'user' },
  [Nav.Submissions]: { title: 'Submissions', access: 'private', layout: 'user' },
  [Nav.Submission]: { title: 'Submission', access: 'private' },
  [Nav.SubmissionForm]: { title: 'Submission Form', access: 'private' },
  [Nav.SubmissionContract]: { title: 'Submission Contract', access: 'private' },
  [Nav.Endorsements]: { title: 'Endorsements', access: 'private' },
  [Nav.Endorsement]: { title: 'Endorsement', access: 'private' },
  [Nav.EndorsementForm]: { title: 'Endorsement Form', access: 'private' },
  [Nav.EndorsementContract]: { title: 'Endorsement Contract', access: 'private' },
  [Nav.EndorsementView]: { title: 'Endorsement View', access: 'private' },
  [Nav.MrcExtraction]: { title: 'MRC Extraction', access: 'private', layout: 'user' },
  [Nav.MrcExtractionDocumentUpload]: { title: 'MRC Extraction Document Upload', access: 'private', layout: 'user' },
};

export default Nav;
