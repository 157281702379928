import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import type { Column } from 'react-table';
import { Table } from '@components/Table';
import { POPOVER_Z_INDEX } from '@constants/z-indices';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { formatDate, sortByDate } from '@root/helpers';
import Nav from '@src/routes';
import clsx from 'clsx';
import { get } from 'lodash-es';

import { CreateEndorsementButton } from './CreateEndorsementButton';

interface ListEndorsementsButtonProps {
  data: EndorsementData[];
  onCreateEndorsement: () => void;
  submission: EntityData;
}

export const ListEndorsementsButton: FC<ListEndorsementsButtonProps> = ({ data, onCreateEndorsement, submission }) => {
  const columns = useMemo<Array<Column<EndorsementData>>>(() => {
    return [
      {
        Header: 'Reference',
        accessor: 'reference',
        Cell: ({ row }) => {
          const value = get(row, 'values.reference', '');
          return <p className="max-w-[168px] truncate whitespace-nowrap text-info-500">{value}</p>;
        },
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        Cell: ({ row }) => {
          const value = get(row, 'values.updated_at', '');
          return <p className="whitespace-nowrap text-info-500">{formatDate(value, 'DD MMM YYYY HH:mm')}</p>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          const value = get(row, 'values.status', '');
          return <PillBadge content={value} />;
        },
      },
      {
        Header: 'Effective',
        accessor: 'effective_date',
        Cell: ({ row }) => {
          const value = get(row, 'values.effective_date', '');
          return <p className="whitespace-nowrap text-info-500">{formatDate(value)}</p>;
        },
      },
    ];
  }, []);

  const handleItemClick = (item: EntityData) => {
    const endorsementContractPath = generatePath(Nav.EndorsementView, {
      id: submission.id,
      endorsement_id: item.id,
    });

    return window.open(endorsementContractPath, '_self');
  };

  return (
    <Listbox>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">List of endorsements</Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-info-600 rounded-md border border-info-300 shadow-sm">
              <div className="relative z-0 inline-flex divide-x divide-info-300 rounded-md shadow-sm">
                <div
                  className={clsx(
                    'relative inline-flex items-center rounded-l-md border border-transparent bg-white py-1 pl-3 pr-4 text-info-700 shadow-sm',
                  )}
                >
                  <p className="ml-2.5 text-sm font-medium">Endorsements</p>
                </div>
                <Listbox.Button
                  className={clsx(
                    'relative inline-flex h-9 items-center rounded-l-none rounded-r-md p-2 py-1 text-sm font-medium text-info-700 hover:bg-info-100 focus:z-10',
                  )}
                >
                  <span className="sr-only">List endorsements</span>
                  <ChevronDownIcon className="h-5 w-5 text-info-400" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  'absolute',
                  'right-0',
                  'mt-2',
                  'w-[600px]',
                  'origin-top-right',
                  'divide-y',
                  'divide-info-200',
                  'overflow-hidden',
                  'rounded-md',
                  'bg-white',
                  'shadow-xl',
                  'ring-1',
                  'ring-black',
                  'ring-opacity-5',
                  'focus:outline-none',
                  POPOVER_Z_INDEX,
                )}
              >
                <div className="max-h-[414px] overflow-y-auto shadow-inner">
                  <Table<EndorsementData>
                    data={sortByDate(data, 'desc', 'updated_at')}
                    columns={columns}
                    onRowClick={handleItemClick}
                  />
                </div>
                <CreateEndorsementButton
                  className="align-start w-full items-center rounded-tl-none rounded-tr-none border-b-0 border-l-0 border-r-0 border-t border-info-200"
                  onCreateEndorsement={onCreateEndorsement}
                />
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
