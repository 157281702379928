import type { EntityData } from '@ContractBuilder/types';
import type { ResourceBlock } from '@root/@types/base';
import { getAllBlocks } from '@root/helpers/blocks';
import { isEndorsementViewPath } from '@utils/app-paths';
import { pick } from 'lodash-es';

export type StateFromBlock = Pick<
  ResourceBlock,
  | 'blockLibraryId'
  | 'canDelete'
  | 'canDeleteOnTemplate'
  | 'canEdit'
  | 'canEditOnTemplate'
  | 'clause_reference'
  | 'clause_type'
  | 'content'
  | 'deleted_at'
  | 'helperText'
  | 'id'
  | 'isVisible'
  | 'linkedDatapoints'
  | 'name'
  | 'repeaterIds'
  | 'section_id'
  | 'selectedVariationId'
  | 'shouldInsertPageBreak'
  | 'title'
  | 'type'
  | 'usedInTemplates'
  | 'variations'
  | 'visibility'
  | 'visibilityComputed'
>;

export const getFormStateFromBlock = (submission?: EntityData, blockId?: string): StateFromBlock | undefined => {
  const isEndorsementView = isEndorsementViewPath();
  const allBlocks = getAllBlocks(submission, isEndorsementView);
  const block = allBlocks.find((item) => item.id === blockId);

  if (!block) {
    return undefined;
  }

  const keysToPick: Array<keyof ResourceBlock> = [
    'blockLibraryId',
    'canDelete',
    'canDeleteOnTemplate',
    'canEdit',
    'canEditOnTemplate',
    'clause_reference',
    'clause_type',
    'content',
    'deleted_at',
    'helperText',
    'id',
    'isVisible',
    'linkedDatapoints',
    'name',
    'repeaterIds',
    'section_id',
    'selectedVariationId',
    'shouldInsertPageBreak',
    'title',
    'type',
    'usedInTemplates',
    'variations',
    'visibility',
    'visibilityComputed',
  ];

  return pick(block, keysToPick) as StateFromBlock;
};
