import type { FC } from 'react';
import { Popover } from '@components/Popover';
import Tooltip from '@components/Tooltip';
import { getTemplatesUrl } from '@ContractBuilder/modules/block-edit/views/BlockUsedInTemplatesModal';
import { useEntityStore } from '@ContractBuilder/store';
import { LinkIcon } from '@heroicons/react/outline';
import { mdiOpenInNew } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import IconMdi from '@root/src/components/IconMdi';
import clsx from 'clsx';

interface BlockUsedWithinProps {
  block: ResourceBlock;
}

const templatesUrl = getTemplatesUrl();

export const BlockUsedWithinDetails = ({
  usedInTemplates,
  label,
}: {
  usedInTemplates?: { id: string; name?: string }[];
  label: string;
}) => (
  <div className="w-60 overflow-x-hidden pl-4">
    <p className="py-2 pr-4 text-xs text-info-600">{label}</p>
    <div className="max-h-96 overflow-y-auto py-1 pr-2">
      {usedInTemplates?.map(({ id, name }) => (
        <div key={id} className="group py-1">
          <a
            href={`${templatesUrl}/${id}/contract`}
            target="_blank"
            className="flex justify-between gap-1 text-xs text-primary-500 group-hover:text-primary-600"
            rel="noreferrer"
          >
            <span className="overflow-hidden text-ellipsis whitespace-nowrap no-underline hover:underline">{name}</span>
            &nbsp;
            <IconMdi
              className="opacity-75 group-hover:opacity-100"
              path={mdiOpenInNew}
              size={0.6}
              data-testid="block-icon-indicator:linked-library-block"
            />
          </a>
        </div>
      ))}
    </div>
  </div>
);

export const BlockUsedWithin: FC<BlockUsedWithinProps> = ({ block }) => {
  const { usedInTemplates } = block;
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const blockUsedInOtherTemplates = usedInTemplates?.filter(({ id }) => id !== submission?.id);

  if (!blockUsedInOtherTemplates?.length) {
    return null;
  }

  return (
    <div className={clsx(['transform', 'transition-all', 'duration-200', 'ease-in-out', 'text-xs'])}>
      <Popover
        id="contract-view-content"
        shouldRenderInsidePortal
        renderTrigger={({ onClick }) => (
          <Tooltip content="Other templates use this block">
            <LinkIcon
              className="h-3.5 w-3.5 text-info-400"
              data-testid="block-icon-indicator:block-used-in-templates"
              onClick={onClick}
            />
          </Tooltip>
        )}
      >
        <BlockUsedWithinDetails label="BLOCK USED IN OTHER TEMPLATES" usedInTemplates={usedInTemplates} />
      </Popover>
    </div>
  );
};
