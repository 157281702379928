import { useCallback, useMemo, useState } from 'react';
import { useClickAway } from 'react-use';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { MentionsHandler } from '@ContractBuilder/modules/mentions-handler/views/MentionsHandler';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { useEntityStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { useDocumentLocation } from '@src/hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

export interface MentionsHandlerControllerState {
  activeDatapointId?: string;
  hasVariations?: boolean;
  isVariationsSelected?: boolean;
}

export const MentionsHandlerController = () => {
  const [state, setState] = useState<MentionsHandlerControllerState>();
  const isEditing = useBlockEditFormStore((state) => state.formValues?.id !== undefined);
  const { submission } = useEntityStore(({ submission }) => ({ submission }));
  const resetFormValues = useDatapointsStore(({ onFormReset }) => onFormReset);

  const variationsMap = useVariationsTabsStore(({ map }) => map);
  const { isEndorsementView } = useDocumentLocation();

  const blocks = useMemo(() => submission?.sections.flatMap((section) => section.blocks) ?? [], [submission?.sections]);

  const isViewingRevisionHistory = useIsRevisionHistory();
  const isEditable = canChange(submission?.status) && !isViewingRevisionHistory;

  const { x, y, strategy, refs } = useFloating({
    placement: 'bottom-start',
    open: state !== undefined,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const isVariationSelected = useCallback(
    (blockId?: string | null) => !!blocks.find((block) => block.id === blockId)?.selectedVariationId,
    [blocks],
  );

  // eslint-disable-next-line no-undef -- TS is taking care of this one
  const handleMentionClick: EventListener = useCallback(
    (event) => {
      event.stopPropagation();
      const spanElement = event.currentTarget as HTMLSpanElement;
      const isCurrentlyEditing = useBlockEditFormStore.getState().formValues?.id !== undefined;

      if (isCurrentlyEditing || isEndorsementView) {
        return;
      }

      if (spanElement) {
        const block = spanElement.closest('[data-test-block-id]');
        const blockId = block?.getAttribute('data-test-block-id');

        const activeDatapointId = spanElement.getAttribute('data-id');

        const hasVariations = !!block?.querySelector('[data-testid^="block-icon-indicator:variations"]');
        const isVariationsSelected = hasVariations ? isVariationSelected(blockId) : false;

        if (activeDatapointId) {
          setState({ activeDatapointId, hasVariations, isVariationsSelected });
          refs.setReference(spanElement);
        }
      }
    },
    [isEndorsementView, isVariationSelected, refs],
  );

  useDeepCompareEffect(() => {
    if (isEditable && !isEndorsementView) {
      const spans = document.querySelectorAll('span[data-type="mention"]');
      spans.forEach((element) => element.addEventListener('click', handleMentionClick));
    }

    return () => {
      const spans = document.querySelectorAll('span[data-type="mention"]');
      spans.forEach((element) => element.removeEventListener('click', handleMentionClick));
    };
  }, [isEndorsementView, variationsMap, isEditing, handleMentionClick, isEditable, submission?.data_items]);

  const handleClose = useCallback(() => {
    resetFormValues();
    return setState(undefined);
  }, [resetFormValues]);

  useClickAway(refs.floating, () => {
    handleClose();
  });

  if (!state?.activeDatapointId || !isEditable) {
    return null;
  }

  return <MentionsHandler onClose={handleClose} strategy={strategy} x={x} y={y} ref={refs.setFloating} {...state} />;
};
