import type { FC } from 'react';
import { Fragment, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Show } from '@components/Show';
import {
  CONTRACT_BUILDER_FOOTER_Z_INDEX,
  CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX,
  CONTRACT_VIEW_Z_INDEX,
} from '@constants/z-indices';
import { ContractBuilderFooter } from '@ContractBuilder/contract-builder-footer';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { NavigationProvider } from '@ContractBuilder/modules/navigation';
import { useEntityStore } from '@ContractBuilder/store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { EndorsementChangesBannerController } from '@features/endorsement-changes-banner';
import { RestoreEndorsementTemplateBannerController } from '@features/restore-endorsement-template-banner';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { isSummarySection } from '@root/helpers';
import { canChange } from '@root/src/helpers/canChange';
import { useCurrentContractSections, useDeepCompareMemo, useDocumentLocation, useFeatureFlags } from '@src/hooks';
import { isEndorsementPath, isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';
import type { MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import { findLastIndex } from 'lodash-es';

import LeftSidebar from './components/LeftSidebar';
import Section from './components/SectionView/Section/Section';
import { AttachmentSection } from './modules/attachment/views/AttachmentSection';
import { EditorToolbarController } from './modules/editor-toolbar';
import { INLINE_BLOCK_EDITOR_NAME } from './modules/editors-map';
import { MentionsHandlerController } from './modules/mentions-handler';
import { getDocumentContext } from './utils/get-document-context';
import { pagesContainerClasses, pagesContainerWrapper, pagesSectionsContainerClasses } from './ContractViewClasses';
import type { EntityData } from './types';

import '../WysiwygEditor/components/Editor/table-borders.css';
import './contract.css';

type StateValue = Record<string, boolean>;

const getInitialState = (submission?: EntityData): StateValue => {
  if (!submission?.sections) {
    return {};
  }

  return submission.sections.map(({ id }) => id).reduce((acc, id) => ({ ...acc, [id]: false }), {});
};

const animationProps: MotionProps = {
  initial: { height: 0, opacity: 0 },
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  transition: { duration: 0.2, delay: 0.1 },
};

export const ContractView: FC = () => {
  const { onCancel } = useBlockEdit();
  const { showHiddenFields } = useUIStore();
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);
  const { attachments, endorsementParent, submission } = useEntityStore(
    ({ attachments, endorsementParent, submission }) => ({
      attachments,
      endorsementParent,
      submission,
    }),
  );
  const isEditing = editingBlockId !== undefined;
  const isTemplate = isTemplatePath();
  const isEndorsementAppPath = isEndorsementPath();
  const isViewingRevisionHistory = useIsRevisionHistory();
  const hasAttachmentsFeature = useFeatureFlags()('Attachments');
  const submissionSections = useCurrentContractSections();

  const { isEndorsementView } = useDocumentLocation();
  const [sections, setSections] = useState<StateValue>(() => getInitialState(submission));

  const disableControls = !canChange(submission?.status) || isEndorsementView || isViewingRevisionHistory;
  const shouldShowCreator = !disableControls && !isViewingRevisionHistory;

  const handleInViewChange = (inView: boolean, _entry: IntersectionObserverEntry, sectionId: string) => {
    setSections((prev) => ({ ...prev, [sectionId]: inView }));
  };

  const activeSectionId = useDeepCompareMemo(() => {
    const defaultSectionId = submissionSections[0]?.id;

    if (editingBlockId) {
      return (
        submissionSections.find(({ blocks }) => blocks.some(({ id }) => id === editingBlockId))?.id ?? defaultSectionId
      );
    }

    return submissionSections.find(({ id }) => sections[id])?.id ?? defaultSectionId;
  }, [editingBlockId, sections, submissionSections]);

  const handleDocumentMarginClick = () => {
    if (editingBlockId) {
      return onCancel();
    }
  };

  const context = getDocumentContext();
  const lastStartSummarySectionIdx = findLastIndex(
    submissionSections,
    (section) => isSummarySection(section) && section.position === 'start',
  );

  return (
    <div className="flex h-full flex-col">
      <div className={clsx(pagesContainerWrapper, isTemplate && 'page-template')} data-cypress="contract-view">
        {!isTemplate && <MentionsHandlerController />}
        <NavigationProvider>
          <LeftSidebar activeSectionId={activeSectionId} isEditing={isEditing} />
        </NavigationProvider>
        <div className={clsx(pagesContainerClasses, 'relative overflow-hidden bg-info-50')}>
          <AnimatePresence>
            {isEditing && (
              <motion.div
                {...animationProps}
                className={clsx('absolute left-0 top-0 flex w-drawer-leftover bg-white', CONTRACT_VIEW_Z_INDEX)}
              >
                <EditorToolbarController
                  currentEditorKey={INLINE_BLOCK_EDITOR_NAME}
                  type={isEndorsementView ? 'endorsementSummary' : 'contract'}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div
            className={clsx(
              pagesContainerClasses,
              isEditing && 'contract-view-content-shrink',
              showHiddenFields ? 'show-fields' : '',
              'contract-view-content overflow-y-auto bg-info-50 pt-6',
            )}
            id="contract-view-content"
            onClick={handleDocumentMarginClick}
          >
            <Show when={isEditing}>
              <div
                id={'contract-view-edit-mode-overlay'}
                className={clsx(
                  'remove-interactive-mention pointer-events-none absolute inset-0 bg-info-50 opacity-50',
                  CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX,
                )}
              />
            </Show>
            <div
              className={clsx(
                pagesSectionsContainerClasses,
                isEditing && 'contract-view-editing',
                'flex flex-col gap-20 pb-8 pt-20',
              )}
            >
              <Show when={isEndorsementView}>
                <RestoreEndorsementTemplateBannerController />
              </Show>
              {submissionSections.map((section, idx) => {
                const isCurrentSectionSummarySection = isSummarySection(section);
                const shouldDisplayChangesBanner = isEndorsementView && idx === lastStartSummarySectionIdx + 1;

                const shouldShowBlocksCreator = isEndorsementView ? isCurrentSectionSummarySection : shouldShowCreator;

                return (
                  <Fragment key={`contract-section-${section.id}`}>
                    <Show when={shouldDisplayChangesBanner}>
                      <div className="-mb-8 -mt-8">
                        <EndorsementChangesBannerController />
                      </div>
                    </Show>
                    <InView
                      delay={50}
                      key={`section-in-view-${section.id}`}
                      onChange={(inView, entry) => handleInViewChange(inView, entry, section.id)}
                    >
                      {({ ref }) => (
                        <div className={clsx('bg-white shadow-lg')} ref={ref}>
                          <Section
                            context={context}
                            disableControls={!canChange(submission?.status) || isViewingRevisionHistory}
                            endorsementParent={endorsementParent}
                            key={`${section.id}-${section.blocks.length}`}
                            section={section}
                            shouldShowCreator={shouldShowBlocksCreator}
                            submission={submission}
                          />
                        </div>
                      )}
                    </InView>
                  </Fragment>
                );
              })}
              <Show when={attachments?.length > 0 && !isEndorsementAppPath && hasAttachmentsFeature}>
                <AttachmentSection attachments={attachments} />
              </Show>
            </div>
          </div>
          <AnimatePresence initial={false}>
            {isEditing && (
              <motion.div
                {...animationProps}
                className={clsx('sticky bottom-0 w-drawer-leftover bg-white', CONTRACT_BUILDER_FOOTER_Z_INDEX)}
              >
                <ContractBuilderFooter />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
