enum MODALS {
  ATTACHMENT_RENAME = 'AttachmentRenameModal',
  ATTACHMENT_UPLOAD = 'AttachmentUploadModal',
  ADMIN_CLAUSE = 'AdminClauseModal',
  ADMIN_CREATE_TEMPLATE = 'AdminCreateTemplateModal',
  ADMIN_OPEN_PDF_MODAL = 'PdfModal',
  ADMIN_TAG = 'TagModal',
  ADMIN_USER = 'UserModal',
  ADMIN_USER_RESEND_INVITE = 'UserResendInviteModal',
  CREATE_ENDORSEMENT = 'EndorsementModal',
  CONFIRM_DELETE = 'ConfirmBlockDeleteModal',
  CONFIRM_STATUS_FINAL = 'FinalStatusModal',
  CONFIRM_STATUS_FINAL_BRANDING = 'FinalStatusModalBranding',
  EDIT_BLOCK = 'BlockEditModal',
  RESTORE_REVISION = 'RestoreRevisionModal',
  BLOCK_VISIBILITY_MODAL = 'BlockVisibilityModal',
  BLOCK_VISIBILITY_WARNING_MODAL = 'BlockVisibilityWarningModal',
  UPLOAD_IMAGE = 'UploadImageModal',
  CREATE_BRANDING = 'CreateBrandingModal',
}

export default MODALS;
