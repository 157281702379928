import type { Images } from '@domain/schemas/images.schema';
import { imageUpdateStatusSchema } from '@domain/schemas/images.schema';
import { updateImage } from '@mutations/updateImage';
import { ApiPatchOperation } from '@root/@types/types';
import { create } from 'zustand';

interface ImagesStore {
  updateStatus: (id: string, status: Images['status']) => Promise<{ success: boolean; error?: string }>;
  remove: (id: string) => Promise<{ success: boolean; error?: string }>;
}

export const useImagesStore = create<ImagesStore>(() => ({
  updateStatus: async (id, status) => {
    const payload = imageUpdateStatusSchema.safeParse({ id, status });
    if (!payload.success) {
      return { success: false, error: payload.error.message };
    }
    await updateImage(payload.data, id);
    return { success: true };
  },
  remove: async (id) => {
    await updateImage({ operation: ApiPatchOperation.SoftDelete }, id);
    return { success: true };
  },
}));
