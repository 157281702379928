import { Fragment, useState } from 'react';
import DropdownChevron from '@components/DropdownChevron';
import IconMdi from '@components/IconMdi';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import { mdiPlusCircleOutline } from '@mdi/js';
import { doesIncludeSubstring } from '@root/helpers';
import { indentationClasses } from '@WysiwygEditor/components/DatapointInserter/classes';
import { sectionAnimationProps } from '@WysiwygEditor/components/DatapointInserter/constants';
import { RenderItemInner } from '@WysiwygEditor/components/InsertMenu/RenderItemInner';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';

const ID_PREFIX = 'insert-menu-expanded-list-item-';

const titleClasses = cva(
  [
    //
    'cursor-pointer',
    'h-9',
    'py-2',
    'pr-4',
    'leading-tight',
    'truncate',
    'text-gray-900',
    'bg-gray-50',
    'hover:bg-primary-50',
    'border-b',
    'border-gray-200',
    'flex',
    'items-center',
    'group',
    'text-sm',
    'overflow-x-hidden',
    'w-full',
  ],
  {
    variants: {
      level: {
        0: ['font-bold'],
      },
    },
  },
);

export type SelectMenuItem<TId = any, PayloadType = any> = {
  id: TId;
  type: string;
  order: number;
  label: string;
  badgeLabel?: string;
  disableInsert?: boolean;
  children?: SelectMenuItem<TId>[];
  payload?: PayloadType;
};

export type TreeViewProps = {
  items: SelectMenuItem[];
  searchText?: string;
  level?: number;
  disableMotion?: boolean;
  showFirstLevel?: boolean;
  showAllChildren?: boolean;
  onItemClick?: (item: SelectMenuItem) => void;
  getAdditionalItemProps?: (item: SelectMenuItem) => Record<string, any>;
};

const doesIncludeSubstringRecursively = (item: SelectMenuItem, searchText: string): boolean => {
  if (doesIncludeSubstring(item.label, searchText)) return true;
  if (item.children) return item.children.some((child) => doesIncludeSubstringRecursively(child, searchText));
  return false;
};

export const TreeView = (props: TreeViewProps) => {
  const {
    items,
    searchText = '',
    level = 0,
    disableMotion,
    showFirstLevel = false,
    showAllChildren = false,
    onItemClick,
    getAdditionalItemProps,
  } = props;

  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>(
    showFirstLevel ? items?.map((item) => item.id).reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {} : {},
  );
  const isAlwaysOpen = disableMotion;

  const onExpandToggle = (id: string) => {
    setIsExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const entries = items.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));
  const filteredEntries = entries.filter(
    (entry) => showAllChildren || doesIncludeSubstringRecursively(entry, searchText),
  );

  return (
    <>
      {filteredEntries.map((entry) => {
        if (entry.children?.length) {
          const childElements = (
            <TreeView
              items={entry.children}
              onItemClick={onItemClick}
              searchText={searchText}
              level={level + 1}
              disableMotion={disableMotion}
              getAdditionalItemProps={getAdditionalItemProps}
              showAllChildren={showAllChildren || doesIncludeSubstring(entry.label, searchText)}
            />
          );

          const entryElement = (
            <Fragment key={entry.label}>
              <button
                className={titleClasses({ level: level as any })}
                title={entry.label}
                onClick={() => onExpandToggle(entry.id)}
                data-testid="toggle-expanded"
              >
                <div
                  className={indentationClasses({
                    level: level as any,
                    className: ['flex', 'items-center', 'gap-2'],
                  })}
                >
                  {!isAlwaysOpen && (
                    <DropdownChevron
                      open={!isExpanded[entry.id]}
                      direction="right"
                      className="inline-flex shrink-0 text-info-600"
                    />
                  )}
                  <h4
                    data-testid={entry.label}
                    className={entry.badgeLabel ? 'max-w-[380px] truncate' : 'max-w-[480px] truncate'}
                  >
                    {entry.label}
                  </h4>
                </div>

                {entry.badgeLabel && (
                  <div className="ml-auto flex gap-1">
                    {onItemClick && <PillBadge content={entry.badgeLabel} className="!text-xs font-normal" />}
                    {!entry.disableInsert && onItemClick && (
                      <IconMdi
                        data-testid="insert-section-repeater-button"
                        path={mdiPlusCircleOutline}
                        className={'text-info-500  hover:text-primary-500'}
                        onClick={() => onItemClick?.(entry)}
                      />
                    )}
                  </div>
                )}
              </button>
              <>
                {disableMotion || isAlwaysOpen ? (
                  childElements
                ) : (
                  <AnimatePresence>
                    {isExpanded[entry.id] && <motion.div {...sectionAnimationProps}>{childElements}</motion.div>}
                  </AnimatePresence>
                )}
              </>
            </Fragment>
          );

          if (level > 0) return entryElement;

          return (
            <div className="datapoints-inserter-expanded" key={entry.id}>
              {entryElement}
            </div>
          );
        }

        return (
          <Fragment key={entry.label}>
            <RenderItemInner
              id={`${ID_PREFIX}${entry.id}`}
              onClick={!entry.disableInsert ? () => onItemClick?.(entry) : undefined}
              value={entry.id}
              label={entry.label}
              level={level}
              data-testid={entry.label}
              isConditional={false}
              showPlusSign={!entry.disableInsert && !!onItemClick}
              {...getAdditionalItemProps?.(entry)}
            />
          </Fragment>
        );
      })}
    </>
  );
};
