interface PolicyholderItem {
  name: Array<{ name: string }>;
}

export const getAllPolicyholderNames = (parentSubmissionPolicyholderData: PolicyholderItem[] = []) => {
  return parentSubmissionPolicyholderData
    .flatMap((item) => item.name)
    .map((nameObj) => nameObj.name)
    .join(', ');
};
