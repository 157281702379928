import { DATAPOINT, DERIVED_CONTENT, HEADING, REPEATER } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import { CLAUSE_TYPES } from '@root/helpers';
import { getChildrenWithPath, resolveSchema } from '@root/helpers/schema';
import { isContentRepeater, isRepeater } from '@root/helpers/schema/resolveSchemaTreeFromForm';
import schema from '@root/helpers/schema/schema-cdr.json';
import { cloneDeep } from 'lodash-es';

type BasicSchema = {
  id: string;
  qid?: number;
  title?: string;
  'ui:component'?: string;
  items?: any;
};

const clauseListItems: SelectMenuItem = {
  id: 'clause-list',
  type: HEADING,
  order: 7,
  label: 'Derived content',
  badgeLabel: '',
  children: CLAUSE_TYPES.map((type, order) => ({
    id: `list_of_${type.toLocaleLowerCase().split(' ').join('_')}`,
    type: DERIVED_CONTENT,
    order,
    label: `List of ${type}`,
    badgeLabel: '',
  })),
};

export const getItemsFromSchema = (schema: BasicSchema[]): SelectMenuItem[] => {
  return schema.map((item) => {
    const label = isContentRepeater(item['ui:component']) ? item?.items?.title : item.title;
    const isRepeaterInserter = isRepeater(item['ui:component']);
    const badgeLabel = isContentRepeater(item['ui:component']) ? 'Repeater' : 'Section';
    const children = getChildrenWithPath(item, item.id);

    return {
      label: label ?? '',
      type: isRepeaterInserter ? REPEATER : DATAPOINT,
      id: item.id!,
      order: item.qid ?? Infinity,
      badgeLabel: isRepeaterInserter ? badgeLabel : undefined,
      disableInsert: false,
      payload: item,
      children: children ? getItemsFromSchema(children) : [],
    };
  });
};

export const getSelectMenuItemsNoClauses = (): SelectMenuItem[] => {
  const resolvedSchema = resolveSchema(cloneDeep(schema), {}, true);
  const roots = Object.entries(resolvedSchema?.properties?.SubmissionForm?.properties).map(
    ([key, value]: [string, any]) => ({ ...value, id: key }),
  );

  return getItemsFromSchema(roots);
};

export const getSelectMenuItemsForContracts = (): SelectMenuItem[] => {
  return getSelectMenuItemsNoClauses().concat(clauseListItems);
};
