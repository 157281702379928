import type { FC } from 'react';
import Stick from 'react-stick';
import { DATAPOINT, DERIVED_CONTENT, REPEATER } from '@components/SelectMenu/constants';
import { handleDatapointInsert } from '@components/SelectMenu/handleDatapointInsert';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { useToggle } from '@src/hooks';
import { getSelectMenuItemsForContracts } from '@WysiwygEditor/components/Controls/utils/datapoint-options';
import { InsertIcon } from '@WysiwygEditor/components/InsertMenu/components/InsertIcon';

import { getRepeaterInsert } from './Controls/utils/handle-repeater-insert';

interface SelectMenuContainerProps {
  editor: any;
}

export const ContractInsertMenu: FC<SelectMenuContainerProps> = ({ editor }) => {
  const [isOpen, _onOpen, onClose, onToggle] = useToggle(false);

  const handleRepeaterInsert = getRepeaterInsert(editor, onClose);

  const onItemClick = (item: any) => {
    if (item.type === DATAPOINT || item.type === DERIVED_CONTENT) return handleDatapointInsert(editor, onClose, item);
    if (item.type === REPEATER) return handleRepeaterInsert(item);

    return undefined;
  };

  return (
    <Stick
      style={{
        node: {
          zIndex: 1200,
        },
      }}
      node={
        isOpen ? (
          <SelectMenu
            isSearchable={true}
            items={getSelectMenuItemsForContracts()}
            label="Data points"
            onClose={onClose}
            onItemClick={onItemClick}
          />
        ) : null
      }
      position="bottom left"
      align="top left"
      onClickOutside={onClose}
    >
      <button
        data-testid="insert-datapoint-button"
        onClick={(e) => {
          e.preventDefault();
          return onToggle();
        }}
        className="flex cursor-pointer items-center gap-x-2 rounded-md px-1 py-0.5 text-sm text-info-800 transition-colors hover:bg-primary-50"
        disabled={!editor}
      >
        <InsertIcon />
        Insert
      </button>
    </Stick>
  );
};
