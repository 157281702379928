import type { Environment } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';

const env: Environment = import.meta.env?.VITE_ENVIRONMENT ?? process?.env?.ENVIRONMENT;
const client: string = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;

const FEATURE_FLAGS = {
  Debugger: (env: Environment) => ['local', 'dev', 'staging'].includes(env),
  MrcExtraction: (env: Environment) => ['local'].includes(env) || tenantConfig.isDataExtractionEnabled,
  Endorsements: (env: Environment) => ['local'].includes(env) || tenantConfig.isEndorsementEnabled,
  Comments: (env: Environment) => ['local'].includes(env) || tenantConfig.isCommentsEnabled,
  Attachments: (env: Environment) => ['local'].includes(env) || tenantConfig.isAttachmentsEnabled,
  Revisions: () => false,
  Branding: (env: Environment) =>
    (['local', 'dev', 'ephemeral'].includes(env) && client === 'artificial') || tenantConfig.isBrandingEnabled,
  SSO: () => tenantConfig.isSSOEnabled,
  DataPointConfig: () => false,
};

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export const featureFlagsFunc = (featureFlagName: FeatureFlag) => FEATURE_FLAGS[featureFlagName](env);

/** Central place for feature flags management. */
export const getFeatureFlag = (featureFlagName: FeatureFlag) => FEATURE_FLAGS[featureFlagName].call(null, env);
