import type { FC } from 'react';
import AvatarMenu from '@components/AvatarMenu';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import PublishingOptions from '@ContractBuilder/components/PublishingOptions';
import { ShowCommentsAndActivitySection } from '@ContractBuilder/components/ShowCommentsAndActivitySection';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { EntityData, PublishingOptionType, StatusType } from '@ContractBuilder/types';
import { canChange } from '@helpers/canChange';
import { DocumentSearchIcon, EyeOffIcon, PencilAltIcon } from '@heroicons/react/outline';
import { MODALS } from '@src/constants';
import { useDocumentLocation, useFeatureFlags, useModal } from '@src/hooks';
import clsx from 'clsx';

import { CircleIconWrapper } from '../../../components/CircleIconWrapper';
import { EndorsementsButtonController } from '../../../modules/endorsements-button/controller/EndorsementsButtonController';

interface ContractToolsProps {
  isLoading: boolean;
  isViewingRevisionHistory: boolean;
  onStatusUpdate: (status: StatusType) => Promise<void>;
  publishingOptions: PublishingOptionType[];
  submission: EntityData;
}

export const ContractTools: FC<ContractToolsProps> = ({
  isLoading,
  isViewingRevisionHistory,
  onStatusUpdate,
  publishingOptions,
  submission,
}) => {
  const { isContractView, isTemplate, isEndorsement } = useDocumentLocation();
  const { toggleShowHiddenFields, showHiddenFields, togglePDFPreview, isPDFPreviewOpen } = useUIStore();
  const isEditing = useBlockEditFormStore((state) => state.formValues?.id !== undefined);
  const { showModal } = useModal();
  const getFeatureFlagState = useFeatureFlags();

  const shouldDisablePublishingOptions =
    isLoading || !canChange(submission.status, undefined, 'status-change') || isViewingRevisionHistory || isEditing;

  const hasEndorsementsFeature = getFeatureFlagState('Endorsements');

  const handleOpenEndorsementDetailsModal = () => {
    return showModal(MODALS.CREATE_ENDORSEMENT, { isEdit: true });
  };

  const handlePreviewPdf = () => {
    if (!isViewingRevisionHistory) {
      return togglePDFPreview();
    }
  };

  return (
    <>
      <div className="flex items-center gap-x-4">
        <Show when={!isTemplate && isContractView}>
          <Tooltip content={`${showHiddenFields ? 'Hide' : 'Show'} hidden fields`} disabled={isViewingRevisionHistory}>
            <CircleIconWrapper
              data-cypress="show-hidden-fields-button"
              icon={EyeOffIcon}
              isActive={showHiddenFields}
              onClick={toggleShowHiddenFields}
              wrapperClassName={clsx(
                isViewingRevisionHistory && 'cursor-not-allowed pointer-events-none bg-transparent opacity-50',
                showHiddenFields && 'hover:bg-primary-100',
              )}
            />
          </Tooltip>
        </Show>
        <Tooltip content="Preview PDF" disabled={isViewingRevisionHistory}>
          <CircleIconWrapper
            data-cypress="preview-pdf-button"
            icon={DocumentSearchIcon}
            isActive={isPDFPreviewOpen}
            onClick={handlePreviewPdf}
            wrapperClassName={clsx(
              isViewingRevisionHistory && 'cursor-not-allowed pointer-events-none bg-transparent opacity-50',
            )}
          />
        </Tooltip>
        <ShowCommentsAndActivitySection />
        <Show when={isEndorsement && hasEndorsementsFeature}>
          <Tooltip content="Endorsement Details" disabled={isEditing}>
            <CircleIconWrapper
              data-cypress="edit-endorsement-details"
              icon={PencilAltIcon}
              isActive={false}
              onClick={handleOpenEndorsementDetailsModal}
              wrapperClassName={clsx(
                isViewingRevisionHistory && 'cursor-not-allowed pointer-events-none bg-transparent opacity-50',
                showHiddenFields && 'hover:bg-primary-100',
              )}
            />
          </Tooltip>
        </Show>
        <EndorsementsButtonController submission={submission} />
      </div>
      <PublishingOptions
        isDisabled={shouldDisablePublishingOptions}
        onStatusUpdate={onStatusUpdate}
        publishingOptions={publishingOptions}
        status={submission.status}
      />
      <AvatarMenu />
    </>
  );
};
