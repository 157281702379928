import type { FC } from 'react';
import { memo, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { NavigationController, useNavigation } from '@ContractBuilder/modules/navigation';
import { useEntityStore } from '@ContractBuilder/store';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal } from '@mdi/js';
import { canChange } from '@root/src/helpers/canChange';
import { useLanguage } from '@root/src/language';
import { isEndorsementViewPath } from '@utils/app-paths';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';

import Divider from '../../../components/Divider';

const listControlClasses = cva('cursor-pointer transition-colors', {
  variants: {
    isHighlighted: {
      false: null,
      true: 'text-primary-600',
    },
  },
});

interface LeftSidebarProps {
  activeSectionId?: string;
  isEditing: boolean;
}

const LeftSidebar: FC<LeftSidebarProps> = memo(function LeftSidebar({ activeSectionId, isEditing }) {
  const { openSectionModal } = useNavigation();
  const dispatchCustomEvent = useDispatchCustomEvent();
  const isEndorsementView = isEndorsementViewPath();

  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const { getContent } = useLanguage({ prefix: 'naming.mrc.leftSidebar' });

  const isViewingRevisionHistory = useIsRevisionHistory();
  const isDisabled = !canChange(submission?.status) || isEditing || isViewingRevisionHistory;

  const [highlightedOption, setHighlightedOption] = useState<'expand' | 'collapse' | 'none'>('none');

  const handleExpandSectionsClick = () => {
    setHighlightedOption('expand');
    return dispatchCustomEvent(ArtificialCustomEvent.ToggleExpandSections, true);
  };
  const handleCollapseAllSectionsClick = () => {
    setHighlightedOption('collapse');
    return dispatchCustomEvent(ArtificialCustomEvent.ToggleExpandSections, false);
  };

  const handleClick = () => {
    openSectionModal();
  };

  return (
    <div className={clsx(`flex w-1/3 max-w-drawer flex-col border-r bg-white`)}>
      <div className={clsx('flex h-[50px] shrink-0 items-center justify-between px-4 py-3', 'justify-center gap-4')}>
        <div className="flex flex-col">
          <p className="text-base font-normal leading-6 text-black">{getContent('title')}</p>
        </div>
        <Show when={!isEndorsementView}>
          <Tooltip content="Add new part">
            <div
              className={clsx(
                'flex h-5 w-5 cursor-pointer items-center justify-center rounded border border-info-600 transition-all hover:opacity-75',
                isDisabled && 'pointer-events-none opacity-50',
              )}
            >
              <Icon
                aria-label="Add new part"
                name="plus"
                onClick={handleClick}
                className={clsx('flex h-3 w-3 items-center hover:opacity-75')}
              />
            </div>
          </Tooltip>
        </Show>
      </div>
      <Divider />
      <NavigationController activeSectionId={activeSectionId} />

      <div className="flex h-[53px] shrink-0 items-center gap-x-1.5 border-t px-4 text-sm font-normal leading-[18px] text-info-600">
        <Button kind="secondary" className="flex h-8 items-center gap-2 px-3">
          <IconMdi
            path={mdiUnfoldMoreHorizontal}
            className={listControlClasses({ isHighlighted: highlightedOption === 'expand' })}
          />
          <p onClick={handleExpandSectionsClick}>{getContent('controls.expand')}</p>
        </Button>
        <Button kind="secondary" className="flex h-8 items-center gap-2 px-3">
          <IconMdi
            path={mdiUnfoldLessHorizontal}
            className={listControlClasses({ isHighlighted: highlightedOption === 'collapse' })}
          />
          <p onClick={handleCollapseAllSectionsClick}>{getContent('controls.collapse')}</p>
        </Button>
      </div>
    </div>
  );
}, isEqual);

export default LeftSidebar;
