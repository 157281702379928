import React, { useState } from 'react';
import { useUserStore } from '@Auth/store';
import Tooltip from '@components/Tooltip';
import { BlockEditProvider } from '@ContractBuilder/modules/block-edit';
import { BlockEditModalController } from '@ContractBuilder/modules/block-edit-modal';
import { EditorsMapProvider } from '@ContractBuilder/modules/editors-map';
import { useEntityStore } from '@ContractBuilder/store';
import { initialBlocksPageFilters, initialBlocksTableColumns } from '@pages/User/constants';
import type { ResourceBlock } from '@root/@types/base';
import type { Action } from '@root/@types/types';
import { isSuperadmin } from '@root/helpers/permissions';
import { useEffectOnce } from '@src/hooks';
import { fetchBlocks } from '@src/queries';
import clsx from 'clsx';

import withMenuLayout from '../../layout/withMenuLayout';

import { accumulateFilterOptions, insertNewOptions } from './components/prepareDropdownOptions';
import { Table } from './components';
import { useDataQuery } from './hooks';

interface ModalState {
  blockId?: string;
  mode: 'create' | 'edit';
}

type FetchBlocksResponseType = Awaited<ReturnType<typeof fetchBlocks>>;

export const Blocks: React.FC = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  const { resetState } = useEntityStore(({ resetState }) => ({ resetState }));
  const { user } = useUserStore(({ user }) => ({ user }));

  const [columns, setColumns] = useState(initialBlocksTableColumns);
  const { sortOrder, data, status, filters, setFilters, toggleSortOrderDirection } = useDataQuery(
    ['blocks', lastUpdatedAt].join(),
    initialBlocksPageFilters,
    fetchBlocks,
    (data: FetchBlocksResponseType) => {
      const filtersWithNameOptions = insertNewOptions(
        filters,
        accumulateFilterOptions(
          (data?.names ?? []).map((name: string) => ({ name })),
          'name',
        ),
        'name',
      );

      setFilters([...filtersWithNameOptions]);
      setTotalAmount(data.totalAmount);
    },
  );
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const handleCreateBlockClick = () => {
    setModalState({ mode: 'create' });
  };

  const handleEditBlockClick = (blockId: string) => async () => {
    try {
      setModalState({ mode: 'edit', blockId });
    } catch {
      /* empty */
    }
  };
  const handleClose = () => setModalState(null);

  useEffectOnce(() => {
    resetState();

    const clonedColumns = [...columns];
    const editBlockColumn = clonedColumns[clonedColumns.findIndex((i) => i.key === 'edit')];

    editBlockColumn.render = (item: ResourceBlock) => {
      const { canEditOnTemplate } = item;
      const isUserSuperAdmin = isSuperadmin(user);

      const isDisabled = !canEditOnTemplate && !isUserSuperAdmin;

      return (
        <td className="relative w-20 truncate whitespace-nowrap pl-3 pr-4 text-right align-top text-sm font-medium">
          <Tooltip content="This block can only by edited by a superadmin" disabled={!isDisabled}>
            <button
              className={clsx(
                'cursor-pointer truncate whitespace-nowrap py-4 pl-3 pr-2 text-right text-sm font-medium text-primary-600 shadow-none transition-colors hover:text-primary-900 disabled:cursor-not-allowed disabled:opacity-50',
              )}
              disabled={isDisabled}
              onClick={handleEditBlockClick.call(null, item.id)}
            >
              Edit
            </button>
          </Tooltip>
        </td>
      );
    };

    setColumns(clonedColumns);
  });

  const actions: Action[] = [
    {
      key: 'create_block',
      label: 'New Block',
      onClick: handleCreateBlockClick,
    },
  ];

  return (
    <EditorsMapProvider>
      <Table
        actions={actions}
        columns={columns}
        entitiesLabel="blocks"
        filters={filters}
        sortOrder={sortOrder}
        toggleSortOrderDirection={toggleSortOrderDirection}
        filteredData={data}
        status={status}
        isServerSidePagination
        totalAmount={totalAmount}
      />
      <BlockEditProvider>
        <BlockEditModalController
          blockId={modalState?.blockId}
          open={!!modalState}
          handleClose={handleClose}
          onAfterSubmit={() => setLastUpdatedAt(new Date().toISOString())}
        />
      </BlockEditProvider>
    </EditorsMapProvider>
  );
};

export const UserBlocksPage = withMenuLayout(Blocks);
