import { DATAPOINT, DERIVED_CONTENT, ENDORSEMENT_DATA } from '@components/SelectMenu/constants';
import { createMention } from '@root/helpers/editor';

import type { SelectMenuItem } from './TreeView';

export const handleDatapointInsert = (editor: any, onClose: Function, item: SelectMenuItem) => {
  if (item.type === DATAPOINT || item.type === ENDORSEMENT_DATA) {
    onClose();
    editor.commands.insertContent(createMention(item.id));
    editor.commands.focus();
  }

  if (item.type === DERIVED_CONTENT) {
    onClose();
    const mention = createMention(item.id, 'mention-list');
    editor.commands.insertContent(mention, { parseOptions: { preserveWhitespace: false } });
  }
};
