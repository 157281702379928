import { EndorsementPremiumChange, StandardisedSectionName } from '@root/@types/types';
import { CLAUSE_TYPES } from '@root/helpers/clauses/constants';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { z } from 'zod';

import type { PK } from './database.schema';
import { baseDatabaseSchema, userSchema } from './database.schema';

export const DbContractSchema = z
  .object({
    name: z.string(),
    status: z.enum(['DRAFT', 'FINAL', 'ARCHIVED', 'PUBLISHED']),
    version: z.number(),
    data_items: z.any(),
    layout: z.array(z.string()),
    attachments_layout: z.array(z.string()).optional(),
    document_name: z.string().optional(), // deprecated, can we remove it?
    document_type: z.string().optional(), // why do we need this? we only have one type which is MRC
    document_version: z.number().optional(), // Isn't this duplicated inside of documents?
    documents: z.array(
      z
        .object({
          name: z.string(),
          subtype: z.string().optional(),
          type: z.enum(['template-document', 'submission-document', 'endorsement-document', 'endorsement-summary']),
          version: z.number(),
          path: z.string().optional(),
        })
        .strict(),
    ), // should this be an array? won't there always just be one document per resource type?
    sourceResourceId: z.string().uuid(),
    sourceResourceName: z.literal('templates'),
    sourceResourceDisplayName: z.string().optional(),
    teams: z.array(z.union([z.string(), z.number()])).optional(), // number for PRO-clients and string for PPL
    tags: z.array(z.string()).optional(),
    branding: z.string(),
    class_of_business: z.enum(ALL_CLASSES_OF_BUSINESS),
    external_id: z.string().optional(), // used for external PRO-clients API
    transaction_status: z.enum(['pending', 'complete']).optional(),

    // to be removed
    ref: z.string(), // i'd consider removing this, raise with JP
    schema_id: z.string().optional(), // remove ASAP
    revision_id: z.string().optional(), // remove
    revision_name: z.string().optional(), // remove
    revision_created_at: z.string().optional(), // remove
    umr: z.string().optional(), // remove
    tenant_id: z.string().optional(), // remove
  })
  .merge(baseDatabaseSchema);

export const DbEndorsementSchema = DbContractSchema.extend({
  additional_premium: z.object({ amount: z.number(), currency: z.string() }).optional(),
  effective_date: z.string().datetime(), // should this be optional?
  expiry_date: z.string().datetime().optional(), // should this be optional?
  premium_change: z.nativeEnum(EndorsementPremiumChange).optional(), // should this be optional?
  reason: z.string().optional(), // should this be optional?
  reference: z.string().optional(), // should this be optional?
  summary_layout: z.array(z.string()).optional(),
  blocks_excluded_from_diff: z.array(z.string()).optional(),
  type: z.string().optional(), // this is an enum?
  summary: z.object({
    blocksExcludedFromPreview: z.array(z.string()).default([]),
    layout: z.array(z.string()).default([]),
  }),

  // to be removed
  endorsement_amendment_text: z.string().optional(), // deprecated?
  endorsement_summary: z.string().optional(), // deprecated?
  parentId: z.string(), // deprecated?
}).strict();

export const DbSectionSchema = z
  .object({
    label: z.string(),
    layout: z.array(z.string()),
    should_hide_title_in_pdf: z.boolean().optional(),
    should_reset_page_counter: z.boolean().optional(),
    standardised_name: z.nativeEnum(StandardisedSectionName).optional(),
    // to be removed
    parentSectionId: z.string().optional(),
  })
  .merge(baseDatabaseSchema)
  .strict();

export const BlockVisibilityConfigSchema = z
  .object({
    conditions: z.array(
      z.object({
        field: z.string(),
        operator: z.enum(['IS', 'IS NOT', 'IS EMPTY', 'IS NOT EMPTY']),
        value: z.union([z.string(), z.array(z.string()), z.number(), z.boolean()]).optional(), // is this correct?
      }),
    ),
    match: z.enum(['AND', 'OR']).optional(),
    mode: z.enum(['SHOW', 'HIDE', 'AUTOSELECT']),
  })
  .strict();

export const BlockVariationVisibilityConfigSchema = BlockVisibilityConfigSchema.extend({
  id: z.string(),
}).strict();

export const DbBlockSchema = z
  .object({
    name: z.string(),
    section_id: z.string().uuid(),
    clause_reference: z.string().optional(),
    clause_type: z.enum(CLAUSE_TYPES).optional(),
    content: z.string(),
    helperText: z.string().optional(),
    selectedVariationId: z.union([z.string().uuid(), z.literal('@@default@@')]).optional(),
    shouldInsertPageBreak: z.boolean().optional(),
    title: z.string().optional(),
    type: z.enum(['clause', 'generic', 'mrc-heading']),
    canEdit: z.boolean().optional(),
    canDelete: z.boolean().optional(),
    canEditOnTemplate: z.boolean().optional(),
    canDeleteOnTemplate: z.boolean().optional(),
    blockLibraryId: z.string().optional(),
    commentsCount: z.number().optional(),
    visibility: z
      .object({
        block: BlockVisibilityConfigSchema.optional(),
        variations: z.array(z.union([BlockVariationVisibilityConfigSchema, z.null()])).optional(),
      })
      .optional(),
    variations: z
      .array(
        z.object({
          id: z.string(),
          title: z.string(),
          content: z.string(),
          // to be removed
          name: z.string().optional(), // why do we need this?
          linkedDatapoints: z.array(z.object({ id: z.string(), isRequired: z.boolean() })),
          repeaterIds: z.array(z.string()),
          isComplete: z.boolean().optional(), // what is this?
          created_by: userSchema.optional(), // why do we need this?
        }),
      )
      .optional(),
    // to be removed
    systemHelperText: z.array(z.string()).optional(), // what is this?
    order: z.number().optional(),
    usedInTemplates: z.array(z.object({ id: z.string(), name: z.string().optional() })).optional(),
    mrc_heading_type: z.string().optional(),
    copied_from_template_id: z.string().optional(),
    extractionCandidateId: z.string().optional(),
    isVisible: z.boolean().optional(),
    linkedDatapoints: z.array(z.object({ id: z.string(), isRequired: z.boolean() })),
    repeaterIds: z.array(z.string()),
    isComplete: z.boolean().optional(), // what is this?
  })
  .merge(baseDatabaseSchema)
  .strict();

export type DbContract = z.infer<typeof DbContractSchema> & PK;
export type DbTemplate = z.infer<typeof DbContractSchema> & PK;
export type DbSubmission = z.infer<typeof DbContractSchema> & PK;
export type DbEndorsement = z.infer<typeof DbEndorsementSchema> & PK;
export type DbSection = z.infer<typeof DbSectionSchema> & PK;
export type DbBlock = z.infer<typeof DbBlockSchema> & PK;
