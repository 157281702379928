import type { PartitionKey } from '@backend/types';
import { z } from 'zod';

export const userSchema = z.object({
  email: z.string(),
  id: z.string(),
  name: z.string(),
  role: z.enum(['admin', 'user', 'superadmin', 'template_manager']),
  tenant_id: z.string().uuid(),
  teams: z.string().optional(),
  organisation_id: z.string().uuid().optional(),
});

export const partitionKeySchema = z.union([
  z.literal('submissions'),
  z.literal('templates'),
  z.literal('endorsements'),
  z.literal('brandings'),
  z.literal('blocks'),
  z.literal('users'),
  z.literal('tags'),
  z.string().regex(/^submissions#.+$/),
  z.string().regex(/^templates#.+$/),
  z.string().regex(/^endorsements#.+$/),
]);

export const baseDatabaseSchema = z.object({
  created_at: z.string(),
  created_by: userSchema.partial({ tenant_id: true }),
  deleted_at: z.nullable(z.string()).optional(),
  deleted_by: userSchema.partial({ tenant_id: true }).optional(),
  id: z.string(),
  last_updated_by: userSchema.partial({ tenant_id: true }).optional(),
  pk: z.string(),
  sk: z.string(),
  updated_at: z.string().optional(),
  update_type: z.string().optional(),
});

export const tenantNameSchema = z.enum(['ct-ppl', 'ct-edbroking', 'ct-lockton', 'ct-bms', 'ct-ajg', 'ct-howden']);

export type PK = { pk: PartitionKey };
export type TenantName = z.infer<typeof tenantNameSchema>;
export type User = z.infer<typeof userSchema>;
export type DbResource = z.infer<typeof baseDatabaseSchema>;
