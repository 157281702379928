import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { isEndorsementData } from '@ContractBuilder/types';
import { numberFormatter } from '@helpers/formatters';
import type { BlockMentionValues } from '@root/@types/types';
import { SummaryDatapointId } from '@root/@types/types';
import { formatDate, isEmptyValue, isNonNullish } from '@root/helpers';
import { getAllPolicyholderNames } from '@root/helpers/mentions';
import { get } from 'lodash-es';

const isValidSummaryDatapointId = (id: string): id is SummaryDatapointId =>
  Object.values(SummaryDatapointId).includes(id as SummaryDatapointId);

const getParsedValue = (datapointId: string, endorsement: EndorsementData, parentData?: EntityData) => {
  const value = get(endorsement, datapointId);
  const createdAt = get(endorsement, 'created_at');
  const updatedAt = get(endorsement, 'updated_at');

  switch (datapointId) {
    case SummaryDatapointId.Policyholders:
      return getAllPolicyholderNames(get(parentData, datapointId));

    case SummaryDatapointId.UniqueMarketReference:
      return get(parentData, datapointId);

    case SummaryDatapointId.PremiumAmount:
      return isNonNullish(value) ? numberFormatter(value) : undefined;

    case SummaryDatapointId.TimeUpdatedAt:
      return formatDate(updatedAt, 'HH:mm');

    case SummaryDatapointId.TimeCreatedAt:
      return formatDate(createdAt, 'HH:mm');

    case SummaryDatapointId.DateCreatedAt:
    case SummaryDatapointId.ExpiryDate:
    case SummaryDatapointId.EffectiveDate:
    case SummaryDatapointId.DateUpdatedAt:
      return formatDate(value, 'D MMMM YYYY');

    default:
      if (isEmptyValue(value)) {
        return undefined;
      }

      return value;
  }
};

export const getEndorsementMentionValues = <T extends EntityData | EndorsementData>(
  data: T,
  ids: string[] = [],
  parentData?: EntityData,
): BlockMentionValues => {
  if (!isEndorsementData(data)) {
    return {};
  }

  return ids.filter(isValidSummaryDatapointId).reduce((acc, id) => {
    const value = getParsedValue(id, data, parentData);

    return {
      ...acc,
      [id]: value,
    };
  }, {});
};
