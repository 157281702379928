import type { BrandingCreate } from '@domain/schemas/branding.schema';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

export const createBranding = async (data: BrandingCreate): Promise<{ id: string }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: '/brandings',
    data,
  });

  return (await axios(networkPayload)).data;
};
