import { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { ContractBuilderDataExtractionLoader } from '@ContractBuilder/ContractBuilderDataExtractionLoader';
import ContractBuilderLayout from '@ContractBuilder/ContractBuilderLayout';
import { ContractView } from '@ContractBuilder/ContractView';
import { ContractBuilderLoaderController } from '@ContractBuilder/modules/contract-builder-loader';
import { PDFPreviewDrawer } from '@ContractBuilder/modules/pdf-preview';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { getPendingDataExtraction } from '@ContractBuilder/utils';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { DocumentMode } from '@root/@types/types';
import * as Sentry from '@sentry/react';
import { useEffectOnce } from '@src/hooks';
import { isTemplatePath } from '@utils/app-paths';
import { AxiosError } from 'axios';

import ContractForm from '../containers/ContractBuilder/ContractForm';
import { PageContainer } from '../layout/PageContainer';
import { getResourcesListToFetch } from '../utils/get-resources-list-to-fetch';

import { ErrorPage } from './ErrorPage';

const isNotFoundError = (error: Error) => error instanceof AxiosError && error.response?.status === 404;

const ContractBuilderPage = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(false);
  const { id, endorsement_id } = useParams<{ id: string; endorsement_id?: string }>();
  const { reloadSubmission, submission, resetState, resetCollapsedSections } = useEntityStore(
    ({ reloadSubmission, submission, resetState, resetCollapsedSections }) => ({
      reloadSubmission,
      submission,
      resetState,
      resetCollapsedSections,
    }),
  );
  const { resolvedSchema } = useSchemaStore(({ resolvedSchema }) => ({
    resolvedSchema,
  }));

  const { mode } = getDocumentMode(location.pathname);
  const isTemplate = isTemplatePath();

  useEffect(() => {
    if (id) {
      resetState();
      const fieldSet = getResourcesListToFetch(mode === DocumentMode.ENDORSEMENTS);
      reloadSubmission(fieldSet).catch((error) => {
        setHasError(true);

        if (!isNotFoundError(error)) {
          Sentry.captureException(error);
        }
      });
    }
  }, [id, endorsement_id, mode, reloadSubmission, resetState]);

  useEffect(() => {
    resetCollapsedSections();
  }, [id, resetCollapsedSections]);

  useEffectOnce(() => {
    const animation = requestAnimationFrame(() => {
      setEnabled(true);
    });

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  });

  useEffectOnce(() => {
    return () => {
      useDatapointsStore.getState().reset();
      useDrawerStore.getState().closeDrawer();
      useUIStore.getState().reset();
    };
  });

  if (hasError) {
    const docType = isTemplate ? 'template' : 'submission';
    console.error(`Unable to load ${docType}: ${id}`);
    return <ErrorPage type="document" docType={docType} />;
  }

  if (!submission || !enabled || !resolvedSchema) {
    return <ContractBuilderLoaderController />;
  }

  const pendingDataExtraction = getPendingDataExtraction(submission);

  return (
    <PageContainer>
      <ContractBuilderLayout>
        <PDFPreviewDrawer />
        {pendingDataExtraction ? (
          <ContractBuilderDataExtractionLoader extractionId={pendingDataExtraction.id} submissionId={submission.id} />
        ) : (
          <Routes>
            <Route path="/form" element={<ContractForm key="form" />} />
            <Route path="/contract" element={<ContractView key="contract" />} />
            <Route path="/endorsement-view" element={<ContractView key="endorsement-view" />} />
          </Routes>
        )}
      </ContractBuilderLayout>
    </PageContainer>
  );
};

export default ContractBuilderPage;
