import React from 'react';
import type { EntityData } from '@ContractBuilder/types';
import StatusBadge from '@pages/User/components/StatusBadge';
import { tagsAccessor } from '@pages/User/helpers/columns/shared/tagsAccessor';
import { teamsAccessor } from '@pages/User/helpers/columns/shared/teamsAccessor';
import type { RootResourceStatus, Tag } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';

type Template = EntityData & { created_by: string };

const columnHelper = createColumnHelper<Template>();

export const getTemplatesTableColumns = ({
  tags,
  setIsLoading,
}: {
  tags: Tag[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('ref', {
    id: 'ref',
    enableColumnFilter: false,
    header: 'Template ID',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All IDs',
      },
      dataCellClassName: '!w-36',
      headingCellClassName: '!w-36',
    },
    sortingFn: (rowA, rowB) =>
      Number((rowA.original.ref ?? '').replace('ART-T-', '')) - Number((rowB.original.ref ?? '').replace('ART-T-', '')),
  }),
  columnHelper.accessor('name', {
    id: 'name',
    enableColumnFilter: false,
    header: 'Name',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        label: 'Template name',
        placeholder: 'All templates',
      },
    },
  }),
  columnHelper.accessor('class_of_business', {
    id: 'class_of_business',
    header: 'Class of Business',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All classes',
        options: ALL_CLASSES_OF_BUSINESS,
      },
    },
  }),
  teamsAccessor<Template>(columnHelper),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<Template, RootResourceStatus>) => <StatusBadge status={cellContext.getValue()} />,
    meta: {
      filter: {
        placeholder: 'All statuses',
        selected: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED'),
      },
      dataCellClassName: '!w-24 align-top !p-[1px]',
      headingCellClassName: '!w-24',
      headingLinkClassName: '!pl-0',
    },
  }),
  tagsAccessor<Template>(columnHelper, tags, setIsLoading),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    enableColumnFilter: false,
    sortingFn: 'datetime',
    sortUndefined: -1,
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Template, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD/MM/YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: '!w-[8.3rem]',
      headingLinkClassName: '!pl-0',
      dataCellClassName: '!w-[8.3rem] !pl-0',
    },
  }),
  columnHelper.display({
    id: 'view',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Template, unknown>) => (
      <a
        className="font-light text-primary-600 hover:text-primary-900"
        target="_self"
        href={`/templates/${cellContext.row.getValue('id')}/contract`}
        rel="noreferrer"
      >
        View
      </a>
    ),
    meta: {
      dataCellClassName:
        'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  }),
];
