import type { FC } from 'react';
import Stick from 'react-stick/lib/Stick';
import { handleDatapointInsert } from '@components/SelectMenu/handleDatapointInsert';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { endorsementSummaryDatapoints } from '@features/endorsement-summary-insert-menu/constants';
import { useToggle } from '@src/hooks';
import { InsertIcon } from '@WysiwygEditor/components/InsertMenu/components/InsertIcon';

interface EndorsementSummaryInsertMenuControllerProps {
  editor: any;
}

export const EndorsementSummaryInsertMenuController: FC<EndorsementSummaryInsertMenuControllerProps> = ({ editor }) => {
  const [isOpen, _onOpen, onClose, onToggle] = useToggle(false);

  const onItemClick = (item: any) => {
    return handleDatapointInsert(editor, onClose, item);
  };

  return (
    <Stick
      style={{
        node: {
          zIndex: 1200,
        },
      }}
      node={
        isOpen ? (
          <SelectMenu
            className="max-h-[32rem] shadow-none"
            disableMotion
            isSearchable={false}
            items={endorsementSummaryDatapoints}
            label="Data points"
            onClose={onClose}
            onItemClick={onItemClick}
          />
        ) : null
      }
      position="bottom left"
      align="top left"
      onClickOutside={onClose}
    >
      <button
        data-testid="insert-datapoint-button"
        onClick={onToggle}
        className="flex cursor-pointer items-center gap-x-2 rounded-md px-1 py-0.5 text-sm text-info-800 transition-colors hover:bg-primary-50"
        disabled={!editor}
      >
        <InsertIcon />
        Insert
      </button>
    </Stick>
  );
};
